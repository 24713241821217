<template>
  <div class="container">
    <BasicTitle title="Nueva Encomienda" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Apartamento *" />
      <BorderSelect
        v-model="shipment.data.apartmentId"
        label="Apartamento *"
        :options="store.state.visit.visitApartments"
      />
      <FormError
        :show="shipment.rules.apartmentId"
        message="Seleccione un apartamento"
      />
      <BasicLabel label="Destinatario *" />
      <BorderInput v-model="shipment.data.destinatary" label="Destinatario *" />
      <FormError
        :show="shipment.rules.destinatary"
        message="Ingrese un destinatario"
      />
      <BasicLabel label="Tipo *" />
      <BorderSelect
        v-model="shipment.data.typeId"
        label="Tipo *"
        :options="store.state.shipment.types"
      />
      <FormError :show="shipment.rules.typeId" message="Seleccione un tipo" />
      <BasicLabel label="Entregado por" />
      <BorderInput v-model="shipment.data.deliveredBy" label="Entregado por" />
      <FormError />
      <BasicLabel label="Recibido por" />
      <BorderInput
        v-model="shipment.data.recipient"
        label="Recibido por"
        :disabled="true"
      />
      <FormError />
      <BasicLabel label="Fecha *" />
      <BorderDate v-model="shipment.data.date" label="Fecha *" />
      <FormError :show="shipment.rules.date" message="Seleccione una fecha" />
      <BasicLabel label="Comentario" />
      <OutlinedTextArea v-model="shipment.data.comment" />
      <FormError />
      <BorderFile
        :v-model="shipment.selectedDocuments"
        @select="onSelectFiles"
      />
      <FormError />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { validation, actions, aws, dates, openMode } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const shipment = reactive({
      selectedDocuments: [],
      data: {
        id: -1,
        apartmentId: "",
        destinatary: "",
        typeId: "",
        deliveredBy: "",
        recipient: store.state.auth.user.name,
        date: new Date(),
        comment: "",
      },
      rules: {
        apartmentId: false,
        destinatary: false,
        typeId: false,
        date: false,
      },
    });

    const onSelectFiles = (ev) => {
      shipment.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (!shipment.data.apartmentId) {
        shipment.rules.apartmentId = true;
        valid = false;
      }
      if (!shipment.data.destinatary) {
        shipment.rules.destinatary = true;
        valid = false;
      }
      if (!shipment.data.date) {
        shipment.rules.date = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const responsePath = await store.dispatch(
        actions.documentActions.getPath,
        {
          buildingId: store.state.general.selectedBuilding,
          folderName: dates.dateToString(shipment.data.date),
          documentTypeName: "Encomiendas",
        }
      );

      if (responsePath.ok) {
        let count = 0;
        const documents = [];
        const { length } = shipment.selectedDocuments;
        const finish = async () => {
          const request = {
            buildingId: store.state.general.selectedBuilding,
            userId: store.state.auth.user.id,
            apartmentId: shipment.data.apartmentId,
            destinatary: shipment.data.destinatary,
            shipmentTypeId: shipment.data.typeId,
            shipmentStatusId: 2,
            deliveredBy: shipment.data.deliveredBy,
            recipient: shipment.data.recipient,
            date: new Date(shipment.data.date).getTime(),
            comment: shipment.data.comment,
          };

          if (documents.length) {
            request.path = documents[0].path;
            request.name = documents[0].name;
            request.ext = documents[0].ext;
          }

          const response = await store.dispatch(
            actions.shipmentActions.create,
            request
          );
          store.commit("setLoading", false);
          if (response.ok) {
            store.commit("setSelectedShipments", []);
            router.push("/encomiendas/listado");
          } else {
            store.state.toast.add({
              severity: "error",
              summary: "",
              detail: response.message,
              life: 5000,
            });
          }
        };

        if (count === length) finish();

        shipment.selectedDocuments.forEach(async (doc) => {
          const name = doc.name;
          const key = `${responsePath.path}${name}`;
          const uploaded = async () => {
            documents.push({
              path: responsePath.path,
              name: name
                .split(".")
                .slice(0, -1)
                .join("."),
              ext: validation.extensionRegex.exec(name)[0],
            });

            count += 1;
            if (count === length) finish();
          };
          aws.uploadFile(key, doc, uploaded);
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: responsePath.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/encomiendas/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(actions.shipmentActions.type);
      await store.dispatch(
        actions.visitActions.apartments,
        store.state.general.selectedBuilding
      );
      await store.dispatch(
        actions.shipmentActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (shipment.data.apartmentId) shipment.rules.apartmentId = false;
      if (shipment.data.destinatary) shipment.rules.destinatary = false;
      if (shipment.data.date) shipment.rules.date = false;
    });

    return { store, shipment, onSave, onSelectFiles };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
